<script>
export default {};
</script>

<template>
  <!-- <div class="my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12"> -->
  <div class="text-center my-5 not-found">
    <!-- <h1 class="font-weight-bold text-error">
              4
              <span class="error-text">
                0 -->
    <img src="@/assets/NotFound404.png" alt class="error-img" />
    <!-- </span> 4
            </h1> -->
    <h3 class="text-uppercase">Sorry, page not found</h3>
    <div class="mt-5 text-center">
      <!-- <a
                class="btn btn-primary waves-effect waves-light"
                href="https://eiev-app.ae/pages/index.html"
              >Back to Dashboard</a> -->
    </div>
  </div>
  <!-- </div>
      </div>
    </div>
  </div> -->
</template>
<style scoped>
.not-found {
  background-color: #f8f9fa;
  margin: 1rem;
  border-radius: 1rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  padding-bottom: 1rem;
}
</style>
