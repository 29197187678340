<script>
import EditVideoModal from "@/views/components/EditVideoModal";
import PlayVideoModal from "@/views/components/PlayVideoModal";
import DeleteVideoModal from "@/views/components/DeleteVideoModal";
import Draggable from "vuedraggable";
import * as toast from "@/config/toast";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
// import { inject } from "vue";
import { ViewVideos } from "@/config/api/ViewVideos";
import * as ApiGenerate from "@/config/ApiGenerate";
export default {
  components: {
    EditVideoModal,
    PlayVideoModal,
    DeleteVideoModal,
    Draggable,
    PulseLoader,
  },
  data() {
    return {
      title: "Sort Videos",
      videosList: [],
      loadingVideosList: true,
      drag: false,
      sortedVideos: [],
      sortedSearchedVideos: [],
      loadingSortedVideos: false,
      sortingSwitch: true,
      searchTextTable: "",
      selectedVideoEdit: null,
      selectedVideoPlay: null,
      selectedVideoDelete: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activeTab: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        {
          key: "thumbnails",
          sortable: false,
          label: "Video",
          tdClass: "columnUrl",
        },
        {
          key: "title",
          sortable: false,
          label: "Title",
          tdClass: "columnTitle",
        },
        {
          key: "description",
          sortable: false,
          label: "Description",
          tdClass: "columnDesc",
        },
        {
          key: "actions",
          sortable: false,
          tdClass: "columnAction",
        },
      ],
      cardHeightOptions: [
        { value: 10, text: "10x" },
        { value: 12, text: "12x" },
        { value: 14, text: "14x" },
        { value: 16, text: "16x" },
        { value: 18, text: "18x" },
      ],
      selectedSize: 10,
      selectedVideoStatus: 0,
      videoStatusOptions: [
        { index: 0, value: "All" },
        { index: 1, value: "Processing" },
        { index: 2, value: "Uploaded" },
        { index: 3, value: "Failure" },
      ],
      showEditModal: false,
      showPlayModal: false,
      showDeleteModal: false,
      isRandomSort: "0",
    };
  },

  created() {
    // console.log("created");
    this.getVideoPosts();
    this.getSortedVideos();
  },
  mounted() {
    this.totalRows = this.videosList.length;
  },
  methods: {
    onConfirmedDelete(data) {
      console.log("onConfirmedDelete", data);

      // this.videosList = this.videosList.filter((element) => {
      //   return element.id !== data.id;
      // });
      // this.sortedVideos = this.sortedVideos.filter((element) => {
      //   return element.id !== data.id;
      // });
    },
    onConfirmedEdit(data) {
      console.log("onConfirmedEdit", data);
      this.videosList = this.videosList.map((e) => {
        return e.id === data.id ? data : e;
      });
      console.log("data", data, " === ", this.sortedVideos);
      if (this.sortedVideos.length > 0) {
        var sortedVideo = this.sortedVideos.find((x) => x.id === data.id);
        if (sortedVideo !== undefined) {
          this.sortedVideos.find((x) => x.id === data.id).title = data.title;
          this.sortedVideos.find((x) => x.id === data.id).description =
            data.description;
        }

        // this.timelineVideos.find((x) => x.id === data.id).video_status =
        //   data.video_status;
        // this.timelineVideos.find((x) => x.id === data.id).is_featured =
        //   data.is_featured;
      }
    },
    checkThumbnailExists(data) {
      if (data) {
        if (data.thumbnails[0] || data.thumbnails[1] || data.thumbnails[2]) {
          return true;
        }
      }
      return false;
    },
    getThumbnail(data) {
      if (data) {
        if (data.thumbnails[0]) {
          return data.thumbnails[0];
        } else if (data.thumbnails[1]) {
          data.thumbnails[1];
        } else {
          data.thumbnails[2];
        }
      }
    },
    changePerPage(perPage) {
      console.log("changePerPage", perPage);
      this.getVideoPosts(1, this.searchTextTable, perPage);
    },
    searchVideoTable() {
      console.log("searchVideo", this.searchTextTable);
      this.currentPage = 1;
      this.getVideoPosts(this.currentPage, this.searchTextTable, this.perPage);
    },
    updatePage(data) {
      this.getVideoPosts(data, this.searchText, this.perPage);
    },
    async getVideoPosts(page = 1, searchTextTable = "", perPage = 10) {
      this.videosList = [];
      this.loadingVideosList = true;
      var response;

      try {
        const api = ViewVideos.getVideoPosts;
        api.params = {
          page: page,
          search_word: searchTextTable,
          perpage: perPage,
        };
        if (this.selectedVideoStatus !== 0) {
          api.params.video_status = this.selectedVideoStatus;
        }
        response = await ApiGenerate.generateAPI(api);

        this.videosList = response.data.data;
        this.totalRows = response.data.total;
        console.log("toast", toast);
        toast.showToast("success", "Total videos: " + response.data.total);
      } catch (error) {
        console.log("error getVideoPosts", error);
        toast.showToast("error", "Error while fetching video list");
      } finally {
        this.loadingVideosList = false;
      }

      console.log("Response VideoList", response);
    },
    async getSortedVideos() {
      try {
        const api = ViewVideos.getSortedVideos;
        let res = await ApiGenerate.generateAPI(api);
        console.log("Response getSortedVideos", res);
        this.sortedVideos = res.data.videoposts;
        this.sortingSwitch = Boolean(res.data.timeline_sort_enabled);
        this.isRandomSort =
          Boolean(res.data.random_sort_enabled) == true ? "1" : "0";
        toast.showToast(
          "success",
          `Videos in sorting: ${this.sortedVideos.length}`
        );
      } catch (error) {
        console.log("error getSortedVideos", error);
        toast.showToast("error", "Error while fetching video list");
      } finally {
        this.loadingSortingVideos = false;
      }
    },
    async saveSorting() {
      // if (this.sortedVideos.length > 0) {
      this.loadingSortedVideos = true;
      const api = ViewVideos.saveTimeline;
      api.data = {
        enable_timeline_sorting: this.sortingSwitch ? 1 : 0,
        enable_random_sorting: this.isRandomSort == "1",
      };
      api.data.timelinevideos = [];
      for (var i = 0; i < this.sortedVideos.length; i++) {
        var item = this.sortedVideos[i];
        api.data.timelinevideos.push({
          videopostid: item.id,
          sortnumber: i + 1,
        });
      }
      try {
        let response = await ApiGenerate.generateAPI(api);
        console.log("Response", response);
        toast.showToast("success", "Sorting saved");
      } catch (error) {
        toast.showToast("error", "Sorting Failed to save");
      } finally {
        this.loadingSortedVideos = false;
      }
      // }
    },
    clearSorting() {
      this.sortedVideos = [];
    },
    showModalDialog(data, dialog) {
      if (dialog === "play-modal-dialog") {
        this.selectedVideoPlay = data;
        this.showPlayModal = !this.showPlayModal;
      } else if (dialog == "edit-modal-dialog") {
        this.selectedVideoEdit = data;
        this.showEditModal = !this.showEditModal;
      } else {
        this.selectedVideoDelete = data;
        this.showDeleteModal = !this.showDeleteModal;
      }
    },
    closeEditModal() {
      this.showEditModal = !this.showEditModal;
      this.selectedVideoEdit = null;
    },
    closePlayModal() {
      this.showPlayModal = !this.showPlayModal;
      this.selectedVideoPlay = null;
    },
    closeDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
      this.selectedVideoDelete = null;
    },
    onRowClicked(item) {
      window.open(item.url);
    },
    addToSorting(data) {
      if (this.sortedVideos.filter((e) => e.id === data.id).length === 0) {
        if (
          data.video_url_web_status == "uploaded" ||
          data.video_url_web_status == "processing"
        ) {
          this.sortedVideos.push(data);
          toast.showToast("success", "Video added for sorting");
        } else if (data.video_url_web_status == "failure") {
          alert("Video failed to process. Cannot add to sorting");
        }
        // else {
        //   alert("Video is still processing. Wait until processing finishes");
        // }
      } else {
        alert("Video already in sorting");
      }
      console.log("sortedVideos", this.sortedVideos);
    },
    removeVideo(data) {
      console.log("removeVideo", data);
      this.sortedVideos = this.sortedVideos.filter((video) => {
        // if (video.is_featured === false) {
        return video.id !== data.id;
        // }
        // return video;
      });
    },
    toggleSorting(data) {
      this.sortingSwitch = data.target.checked;
    },
    sizeChanged(data) {
      console.log("data", data);
      // for (
      //   let i = 0;
      //   i < document.getElementsByClassName("timeline-item").length;
      //   i++
      // ) {
      //   document.getElementsByClassName("timeline-item")[i].style.height =
      //     data + "rem";
      //   document.getElementsByClassName("timeline-image")[i].style.maxHeight =
      //     data - 4 + "rem";
      // }
    },
  },
  computed: {
    changeSizes() {
      // console.log("size", this.selectedSize === 10);
      return {
        height: this.selectedSize + "rem",
        width: parseInt(this.selectedSize) - 0 + "rem",
      };
    },
    changeSizes_2() {
      // console.log("size", this.selectedSize === 10);
      return {
        height: parseInt(this.selectedSize) - 2 + "rem",
        // width: (parseInt(this.selectedSize) - 2) + "rem",
      };
    },
    rows() {
      return this.totalRows;
    },
    dragOptions() {
      return {
        animation: 400,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    selectedVideoStatus(value) {
      console.log("selectedVideoStatus WATCH", value);
      this.selectedVideoStatus = value;

      this.currentPage = 1;
      this.getVideoPosts(this.currentPage, this.searchTextTable, this.perPage);
    },
    currentPage(value) {
      console.log("currentPage WATCH", value);
      this.updatePage(value);
    },
  },
};
</script>

<template>
  <div class="card-box" id="card-box">
    <div class="timeline-heading">
      <div class="col-6 align-self-center d-flex">
        <div class="d-flex" style="align-items: center !important">
          <div class="timeline-heading-text align-self-center">SORTING</div>
          &nbsp;&nbsp;
          <label class="switch m-0" v-if="!loadingSortedVideos">
            <input
              type="checkbox"
              id="togBtn"
              v-model="sortingSwitch"
              @click="toggleSorting"
            />
            <div class="slider round mr-8">
              <!--ADDED HTML -->
              <span class="on">ON</span>
              <span class="off">OFF</span>
              <!--END-->
            </div>
          </label>
          <div
            style="
               {
                display: inline-block;
                width: 20px;
              }
            "
          ></div>

          <b-row :class="{ disable: sortingSwitch }">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              style="margin-bottom: 0px !important"
            >
              <b-form-radio
                v-model="isRandomSort"
                :aria-describedby="ariaDescribedby"
                name="date_sort_radio"
                value="0"
                >Date</b-form-radio
              >
              <b-form-radio
                v-model="isRandomSort"
                :aria-describedby="ariaDescribedby"
                name="random-sort-radios"
                value="1"
                >Random</b-form-radio
              >
            </b-form-group>
          </b-row>
        </div>
      </div>

      <div class="col-6 end color-red">
        <b-form-select
          v-model="selectedSize"
          @change="sizeChanged"
          :options="cardHeightOptions"
          size="sm"
          style="width: 5rem; display: inline"
        ></b-form-select>
        &nbsp;
        <span class="clear-timeline pointer flexible-font" @click="clearSorting"
          >CLEAR SORTING</span
        >&nbsp;&nbsp;&nbsp;
        <button class="btn btn-success m-0" @click="saveSorting">SAVE</button>
      </div>
    </div>

    <div
      v-if="!loadingSortedVideos"
      class="resizeable-card"
      :style="sortedVideos.length > 0 ? 'height:300px' : 'height:100px'"
    >
      <div v-if="sortedVideos.length == 0" class="no-videos">
        Add Videos for sorting
      </div>
      <div v-else class="d-flex m-0 p-0" :class="{ disable: !sortingSwitch }">
        <draggable v-model="sortedVideos" item-key="id" v-bind="dragOptions">
          <template #item="{ element, index }">
            <div class="timeline-item" :style="changeSizes">
              <div class="d-flex">
                <div class="col-4"></div>
                <div class="col-4">{{ index + 1 }}</div>
                <div class="col-4" style="text-align-last: end">
                  <i
                    class="fa fa-times pointer"
                    @click="removeVideo(element)"
                  ></i>
                </div>
              </div>
              <div class="parent-card" :style="changeSizes_2">
                <img
                  v-if="element.video_url_web_status == 'uploaded'"
                  class="timeline-image"
                  :class="{
                    opacity_low: element.video_url_web_status != 'uploaded',
                  }"
                  :src="element.thumbnails[0]"
                  height
                />
                <img
                  v-else
                  class="timeline-image"
                  src="@/assets/default-placeholder-image.png"
                />
                <img
                  v-if="element.video_url_web_status == 'uploaded'"
                  @click="showModalDialog(element, 'play-modal-dialog')"
                  src="@/assets/play-button.png"
                  draggable="false"
                  class="play-button"
                />
                <div
                  class="processing"
                  v-if="element.video_url_web_status == 'processing'"
                >
                  Processing
                </div>
                <div
                  class="failure"
                  v-if="element.video_url_web_status == 'failure'"
                >
                  Error
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <div v-else class="mt-5 mb-5 pulse-loader">
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
  </div>

  <div class="table-card card-box">
    <div class="col-sm-12 col-md-6">
      <div>
        <label class="d-inline-flex align-items-center">
          Show&nbsp;
          <b-form-select
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            @change="changePerPage"
            style="width: 4rem"
          ></b-form-select
          >&nbsp;entries
        </label>
      </div>

      <div class="d-flex">
        <div class="col-3"><label> Video Type:</label></div>
        <div class="col-6">
          <b-form-select
            class="mb-3"
            v-model="selectedVideoStatus"
            size="sm"
            :options="videoStatusOptions"
            value-field="index"
            text-field="value"
          ></b-form-select>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-3"><label> Search:</label></div>
        <div class="col-6">
          <b-form-input
            v-model="searchTextTable"
            type="search"
            @keydown.enter.prevent="searchVideoTable"
            class="form-control form-control-sm mb-0"
          ></b-form-input>
        </div>
      </div>
    </div>
    <!-- Search -->

    <!-- End search -->

    <div class="table-responsive table-style" v-if="!loadingVideosList">
      <b-table
        class="table-centered"
        v-model="videosList"
        :items="videosList"
        :fields="fields"
        responsive="sm"
        :busy="loadingVideosList"
        :current-page="currentPage"
      >
        <template v-slot:cell(thumbnails)="row">
          <div class="parent-card-table">
            <img
              v-if="row.item.video_url_web_status === 'uploaded'"
              draggable="false"
              class="child-image"
              style="cursor: pointer"
              :src="getThumbnail(row.item)"
              :class="{
                opacity_low: row.item.video_url_web_status != 'uploaded',
              }"
              @click="showModalDialog(row.item, 'play-modal-dialog')"
            />
            <img
              v-else
              draggable="false"
              class="child-image"
              src="@/assets/default-placeholder-image.png"
            />
            <img
              v-if="row.item.video_url_web_status === 'uploaded'"
              @click="showModalDialog(row.item, 'play-modal-dialog')"
              src="@/assets/play-button.png"
              draggable="false"
              class="play-button"
            />
            <div
              class="processing"
              v-if="row.item.video_url_web_status == 'processing'"
            >
              Processing
            </div>
            <!-- <img
                class="featured"
                v-if="row.item.is_featured == '1'"
                src="@/assets/images/banner-featured.png"
                height="100"
              /> -->
            <div
              class="failure"
              v-if="row.item.video_url_web_status == 'failure'"
            >
              Error
            </div>
          </div>
        </template>
        <template v-slot:cell(title)="row">
          <span class="word-break flexible-font">{{ row.item.title }}</span>
        </template>
        <template v-slot:cell(description)="row">
          <span class="word-break flexible-font">{{
            row.item.description
          }}</span>
        </template>
        <template #cell(actions)="row">
          <b-button
            variant="danger"
            @click="showModalDialog(row.item, 'delete-modal-dialog')"
          >
            <i class="fa fa-trash"></i>
          </b-button>
          <b-button @click="showModalDialog(row.item, 'edit-modal-dialog')">
            <i class="fa fa-pencil-square-o"></i>
          </b-button>
          &nbsp;
          <b-button variant="primary" @click="addToSorting(row.item)">
            <i class="fa fa-plus"></i>
          </b-button>
        </template>
      </b-table>
    </div>
    <div style="text-align: center" class="mt-5 mb-5" v-else>
      <pulse-loader :loading="loadingVideosList"></pulse-loader>
    </div>
    <template v-if="videosList.length == 0 && loadingVideosList === false">
      <p style="text-align: center">No videos found</p>
    </template>

    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <EditVideoModal
    :showEditModal="showEditModal"
    :selectedVideoEdit="selectedVideoEdit"
    @close-modal="closeEditModal"
    @confirmed-edit="onConfirmedEdit"
  ></EditVideoModal>

  <PlayVideoModal
    :showPlayModal="showPlayModal"
    :selectedVideoPlay="selectedVideoPlay"
    @close-modal="closePlayModal"
  >
  </PlayVideoModal>
  <DeleteVideoModal
    :showDeleteModal="showDeleteModal"
    :selectedVideoDelete="selectedVideoDelete"
    @close-modal="closeDeleteModal"
    @confirmed-delete="onConfirmedDelete"
  >
  </DeleteVideoModal>
</template>

<style scoped>
.flexible-font {
  font-size: clamp(10px, 1.5vw, 16px);
}
/*--------------------  TIMELINE START --------------------*/
.card-box {
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  min-height: 200px;
  margin: 1rem;
  border-radius: 1rem;
  padding-bottom: 1rem;
}
#card-box::-webkit-resizer {
  background-color: red;
  /* color: red; */
}
.resizeable-card {
  resize: vertical;
  overflow: auto;
}
.timeline-heading {
  margin: 0px 0px 2rem 0px;
  border-bottom: 1px solid #eff2f7;
  padding: 1rem;
  display: flex;
}
.timeline-heading-text {
  color: #343a40;
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
}
.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
  margin-bottom: -10px;
  margin-left: 10px;
}

.switch input {
  display: none;
}
/*--SLIDER START--*/
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1cbb8c;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}
.on {
  display: none;
}
.on,
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}
input:checked + .slider .on {
  display: block;
}
input:checked + .slider .off {
  display: none;
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*--SLIDER END --*/
.end {
  text-align: end;
}
.color-red {
  color: #fc214d;
}
.align-self-center {
  align-self: center;
}
.clear-timeline:hover {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
.no-videos {
  width: 90%;
  border: 2px dashed grey;
  text-align: center;
  padding: 1rem;
  margin: 0 auto;
}
.timeline-item {
  margin: 1rem 0rem 1rem 1rem;
  width: 12rem;
  height: 21rem;
  align-self: center;
  text-align: -webkit-center;
  text-align-last: center;
  text-align: center;
  display: inline-block;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
}
.timeline-item:hover {
  background-color: aliceblue;
  cursor: move;
}
.timeline-item:hover .remove-video {
  color: red;
}
.timeline-image {
  height: 100%;
  max-width:9rem;
}
.play-button:hover {
  width: 20%;
  /* height: 3rem; */
  transition-timing-function: ease-in;
  transition: 0.5s;
  cursor: pointer;
}
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-timing-function: ease-in;
  transition: 0.5s;
  width: 15%;
}
.processing {
  background: rgba(68, 75, 199, 1);
  width: 100%;
  transition: 0.5s ease;
  color: white;
  text-align: center;
  position: absolute;
  bottom: 45%;
  font-size: clamp(12px, 1.5vw, 20px);
}
.failure {
  background: rgba(252, 33, 78, 1);
  width: 100%;
  transition: 0.5s ease;
  color: white;
  text-align: center;
  position: absolute;
  bottom: 45%;
  font-size: clamp(12px, 1.5vw, 20px);
}
.pulse-loader {
  text-align: center;
  padding-bottom: 3rem;
}
/*--------------------  TIMELINE END --------------------*/

.parent-card {
  /* max-height: 21rem;
  min-height: 10rem;
 max-width: 12rem; 
  display: grid; 
  place-items: center;*/
  height: 100%;
  position: relative;
  /* background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%); */
}
.parent-card-table {
  max-height: 21rem;
  min-height: 8rem;
  max-width: 12rem;
  display: grid;
  place-items: center;
  position: relative;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
}
.child-image {
  padding: 10px 10px;
  /* height: 100%; */
  width: 100%;
}

.disable {
  opacity: 0.3;
  pointer-events: none;
}

.table-card {
  padding: 1rem;
  /* margin: 0rem 0rem 2rem 0rem; */
}

.opacity_low {
  opacity: 0.5;
}
.word-break {
  word-break: break-all;
  white-space: break-spaces;
}
.table-style {
  background-color: white;
  margin-top: 2rem;
}
#play-modal-dialog {
  align-items: center;
  justify-content: center;
  display: grid;
}
</style>

<style>
.table td {
  vertical-align: middle;
}

.columnUrl {
  width: 30%;
}
.columnTitle {
  width: 20%;
}
.columnDesc {
  width: 30%;
}
.columnAction {
  width: 20%;
}

.form-check-input:checked[type="radio"] {
  background-color: #1cbb8c !important;
  background-image: none !important;
}
</style>
