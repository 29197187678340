<script>
export default {
  props: ["selectedVideoPlay", "showPlayModal"],

  mounted() {
    this.values = { ...this.selectedVideoPlay };
  },
  data() {
    return {
      title: "-",
      loading: false,
      values: {},
    };
  },
  methods: {
    closeModal() {
      console.log("closeModal");
      this.$emit("close-modal");
    },
  },
  watch: {
    selectedVideoPlay(val) {
      console.log("selectedVideoPlay val", val);
      if (val !== null) {
        this.values = { ...val };
      }
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div v-show="showPlayModal" class="vue-modal">
      <transition name="drop-in" v-show="showPlayModal">
        <div class="vue-modal-inner">
          <div class="vue-modal-content">
            <div
              class="d-flex justify-content-between border-bottom border-light p-3"
            >
              <div>
                <h5>{{ values.title }}</h5>
              </div>
              <div>
                <i class="fa fa-times fa-lg pointer" @click="closeModal"></i>
              </div>
            </div>
            <div class="p-3">
              <p>{{ values.description }}</p>
              <iframe
                :src="values.video_url_web"
                style="border: none"
                height="420"
                width="100%"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
                id="stream-player"
              ></iframe>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
