<style src="@vueform/slider/themes/default.css"></style>
<script>
// import { getFormData } from "@/components/utils/createForm";
import { addVideo } from "@/config/api/UploadVideo";
// import ArgonInput from "@/components/ArgonInput.vue";
// import { createFFmpeg } from "@ffmpeg/ffmpeg";
import Slider from "@vueform/slider";
import axios from "axios";
import * as toast from "@/config/toast";
import * as ApiGenerate from "@/config/ApiGenerate";
// import {ref} from vue;
export default {
  components: { Slider },
  data() {
    return {
      originalRange: [0, 100],
      title: "Upload Video",
      loading: false,
      formTitle: "",
      formDescription: "",
      // isFeatured: "0",
      selectedFile: null,
      minTime: 0,
      maxTime: 0,
      currentTime: 0,
      duration: 0,
      playerOptions: null,
      submitEnabled: false,
      trimMin: 0,
      trimMax: 0,
      message: "Submit to start transcoding",
      video: null,
      file: null,
      stopLoadMetaData: 0,
      isPlaying: false,
      sliderDisabled: true,
      format: {
        decimals: 2,
      },
      isPrivate: false,
      // ffmpeg: createFFmpeg({
      //   corePath: "https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js",
      //   log: true,
      // }),
    };
  },
  created() {},
  mounted() {},
  methods: {
    isTrimmed() {
      return this.minTime === 0 && this.maxTime === this.trimMax ? false : true;
    },
    async directCreatorUpload(responseCreateVideoPost) {
      return await axios.post(
        "https://api.cloudflare.com/client/v4/accounts/8c0f322fb5c5ed8326dc88fe6666c36b/stream/direct_upload",
        {
          maxDurationSeconds: 3600,
          requireSignedURLs: false,
          thumbnailTimestampPct: 0.1,
          meta: {
            videopost_id: responseCreateVideoPost.data.videopost.id.toString(),
          },
        },
        {
          headers: {
            Authorization: `Bearer 78e440e3tkY83mylDoX83O8-5rdq6ffhYmzf4Whf`,
          },
        }
      );
    },
    async uploadVideoOnURL(url) {
      let formData = new FormData();
      formData.append("file", this.selectedFile);
      return await axios.post(url, formData);
    },
    async getVideoByUID(uid) {
      return await axios.get(
        `https://api.cloudflare.com/client/v4/accounts/8c0f322fb5c5ed8326dc88fe6666c36b/stream/${uid}`,
        {
          headers: {
            Authorization: `Bearer 78e440e3tkY83mylDoX83O8-5rdq6ffhYmzf4Whf`,
          },
        }
      );
    },
    async createVideoPost() {
      let video_type = "1";
      const api = addVideo.createVideoPost;

      if (this.isTrimmed()) {
        api.data = {
          title: this.formTitle,
          description: this.formDescription,
          video_type: video_type,
          trim_start: this.minTime,
          trim_end: this.trimMax,
          is_private: this.isPrivate,
        };
      } else {
        api.data = {
          title: this.formTitle,
          description: this.formDescription,
          video_type: video_type,
          is_private: this.isPrivate,
        };
      }
      return await ApiGenerate.generateAPI(api);
    },
    async upload() {
      function myFunction() {
        this.loading = false;
        location.reload();
      }
      this.loading = true;
      this.submitEnabled = false;
      console.log("selectedFile", this.selectedFile);
      var responseUploadOnUrl;
      var responseCreateVideoPost;

      try {
        responseCreateVideoPost = await this.createVideoPost();
        console.log("responseCreateVideoPost", responseCreateVideoPost);

        responseUploadOnUrl = await this.uploadVideoOnURL(
          responseCreateVideoPost.data.uploadURL
        );
        console.log("responseUploadOnUrl", responseUploadOnUrl);

        toast.showToast("success", "Video has been sent for processing");
        setTimeout(myFunction, 2000);
      } catch (error) {
        toast.showToast("error", error.data.error.title.toString());
        console.log("error", error.data.error);
        this.loading = false;
        this.submitEnabled = true;
      } finally {
        // this.submitEnabled = true;
        // this.selectedFile = null;
        // var video = document.getElementById("video");
        // video.src = null;
        // this.formTitle = "";
        // this.formDescription = "";
      }
    },
    sliderChanged(data) {
      console.log("console sliderChanged", data);

      let video = this.$refs["videoPlayer"];
      if (this.isPlaying) {
        if (
          data[0] !== this.originalRange[0] ||
          data[1] !== this.originalRange[1]
        ) {
          console.log("min or max changed");
        }
      }

      video.pause();
      this.isPlaying = false;

      video.currentTime = data[0];
      // this.minTime = parseFloat(data[0]);
      this.trimMin = parseFloat(data[0]);
      this.trimMax = parseFloat(data[1]);
    },
    sliderUpdate(data) {
      console.log("sliderUpdate", data);
    },
    sliderMin() {
      console.log("sliderMin");
      let video = this.$refs["videoPlayer"];
      video.pause();
      this.originalRange[0] = this.trimMin;
      // video.load();
      // video.currentTime = this.minTime;

      // this.trimMin = value;
      // this.flag = false;
      if (this.trimMin >= this.trimMax || this.trimMin < 0) {
        this.trimMin = this.minTime;
        this.originalRange[0] = this.minTime;
        // this.trimMin = this.originalRange[0];
      }
      this.sliderChanged(this.originalRange);
    },

    sliderMax() {
      console.log("sliderMax");
      let video = this.$refs["videoPlayer"];
      video.pause();
      // console.log("maxTime", this.maxTime);
      // console.log("trimMax", this.trimMax);
      if (this.trimMax <= this.trimMin || this.trimMax > this.maxTime) {
        this.trimMax = this.originalRange[1];
      } else {
        this.originalRange[1] = this.trimMax;
      }
      this.sliderChanged(this.originalRange);
      // if (this.currentTime >= parseFloat(value)) {
      //   this.currentTime = this.maxTime - 0.1;
      // }
    },
    consoleValues() {
      console.log("minTime: ", this.minTime, ", maxTime: ", this.maxTime);
      console.log("trimMin: ", this.trimMin, " trimMax:", this.trimMax);
      console.log("originalRange: ", this.originalRange);
    },
    checkMinInput(value) {
      // this.consoleValues();
      // if (parseFloat(value) <= this.trimMax && parseFloat(value) >= 0) {
      this.trimMin = parseFloat(value);
      // }
    },
    checkMaxInput(value) {
      // this.consoleValues();
      this.trimMax = parseFloat(value);
    },
    // checkTrimMin(value) {
    //   console.log("checkTrimMin", value);
    //   if (
    //     parseFloat(value) < this.minTime ||
    //     parseFloat(value) > this.maxTime
    //   ) {
    //     console.log("out of range");
    //     console.log("minTime", this.minTime);
    //     this.trimMin = this.minTime;
    //     value = this.minTime;
    //     // this.originalRange[0] = this.minTime;
    //   } else {
    //     console.log("range ok");
    //     this.trimMin = this.minTime;
    //   }
    // },
    blur(event) {
      // console.log("blur", event);
      if (event.target.id === "minInput") {
        this.checkMinInput(event.target.value);
        this.sliderMin();
      } else {
        this.checkMaxInput(event.target.value);
        this.sliderMax();
      }
      this.sliderChanged(this.originalRange);
    },
    chooseFile(data) {
      console.log("console chooseFile", data);
      this.selectedFile = data.target.files[0];
      // this.selectTheFile(data.target.files[0]);
    },
    getFile() {
      // console.log("event", event);
      // var video = document.createElement("video");
      // video.preload = "metadata";
      // video.onloadedmetadata = () => {
      //   window.URL.revokeObjectURL(video.src);
      //   this.duration = video.duration;
      //   this.maxTime = video.duration;
      //   console.log("Duration : " + video.duration + " seconds");
      // };
      // if (event !== null) {
      //   video.src = URL.createObjectURL(event);
      // }
    },
    inputChanged(data) {
      console.log("console inputChanged", data);
      this.convertDurationtoSeconds(data);
    },
    convertDurationtoSeconds(duration) {
      console.log("console converting...", duration.split(":"));

      // console.log( duration.split(':').reduce((acc,time) => (60 * acc) + +time));
      console.log(
        "console",
        duration
          .split(":")
          .reverse()
          .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0)
      );
    },
    secondsToTimer(sec) {
      let o = new Date(0);
      let p = new Date(sec * 1000);
      return new Date(p.getTime() - o.getTime())
        .toISOString()
        .split("T")[1]
        .split("Z")[0]
        .slice(0, -1);
    },
    // async transcode() {
    //   console.log("transcode bro");
    //   const ffmpeg = createFFmpeg({
    //     log: true,
    //   });
    //   // let maxBitRate = "100k";
    //   // let bufferSize = "100k";
    //   // let videoCodec = "libx264";
    //   // let audioCodec = "aac";
    //   // let audioBitRate = "128k";
    //   // let crf = "52";
    //   // let preset = "ultrafast";
    //   let inputFileName = "test.mp4";
    //   let outputFileName = "output.mp4";
    //   let startTime = this.secondsToTimer(this.trimMin);
    //   // let endTime = this.secondsToTimer(this.trimMax);
    //   let duration = this.secondsToTimer(this.trimMax - this.trimMin);

    //   // let command = `-y -i ${inputFileName} -ss ${startTime} -t ${endTime} -vcodec ${videoCodec} -acodec ${audioCodec} -refs 6 -coder 1 -sc_threshold 40 -flags +loop -me_range 16 -subq 7 -i_qfactor 0.71 -qcomp 0.6 -qdiff 4 -trellis 1 -b:a ${audioBitRate} -maxrate ${maxBitRate} -bufsize ${bufferSize} -crf ${crf} -preset ${preset} -movflags +faststart ${outputFileName}`;
    //   let command = `-y -ss ${startTime} -i ${inputFileName} -t ${duration} -c:v copy -c:a copy ${outputFileName}`;
    //   // let command = `-y -ss ${startTime} -i ${inputFileName} -to ${endTime} ${outputFileName}`;
    //   console.log("command", command);

    //   // let command = "-i test.mp4 -vcodec libx264 -b:v 200k output.mp4`;
    //   let commandSplit = command.split(" ");
    //   // const message = ref("");
    //   // let video = ref(null);
    //   // const file = "/90064381281.mp4";
    //   this.message = "Loading ffmeg-core.js";

    //   await ffmpeg.load();
    //   this.message = "Start transcoding";
    //   // ffmpeg.FS("writeFile", "test.mp4", await fetchFile(this.video));
    //   ffmpeg.FS(
    //     "writeFile",
    //     inputFileName,
    //     new Uint8Array(await this.selectedFile.arrayBuffer())
    //   );
    //   // ffmpeg.FS("writeFile", "watermark.png", await fetchFile(file));

    //   await ffmpeg.run(...commandSplit);
    //   this.message = "Complete transcoding";
    //   const data = ffmpeg.FS("readFile", outputFileName);

    //   let video = this.$refs["videoPlayer"];
    //   video.src = URL.createObjectURL(
    //     new Blob([data.buffer], { type: "video/mp4" })
    //   );

    //   // this.maxTime = this.trimMax;
    //   // this.originalRange = [this.minTime, this.trimMax];
    // },
    onTimeUpdateListener(event) {
      // console.log(
      //   "console onTimeUpdateListener",
      //   event.srcElement.currentTime,
      //   " <= ",
      //   this.trimMax
      // );

      let video = this.$refs["videoPlayer"];
      this.currentTime = parseFloat(event.srcElement.currentTime);
      // this.originalRange[1] = this.currentTime;

      if (this.currentTime > parseFloat(this.trimMax)) {
        console.log("pause video");
        video.pause();
        video.currentTime = this.trimMin;
        this.isPlaying = false;
        // video.play();
      } else if (this.currentTime === parseFloat(this.trimMax)) {
        this.isPlaying = false;
      }
      // else if (video.currentTime < this.minTime) {
      //   console.log("console set currentTime");
      //   video.currentTime = this.minTime;
      // }
    },
    playOrPause() {
      let video = this.$refs["videoPlayer"];
      this.isPlaying ? video.pause() : video.play();
      this.isPlaying = !this.isPlaying;
    },
  },
  computed: {
    playOrPauseAsset() {
      return this.isPlaying
        ? `@/assets/play-button.png`
        : `@/assets/pause-button.png`;
    },
    secondsToMinTime() {
      const h = Math.floor(this.trimMin / 3600);
      const m = Math.floor((this.trimMin % 3600) / 60);
      const s = Math.round(this.trimMin % 60);
      return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
        .filter(Boolean)
        .join(":");
    },
    secondsToMaxTime() {
      const h = Math.floor(this.trimMax / 3600);
      const m = Math.floor((this.trimMax % 3600) / 60);
      const s = Math.round(this.trimMax % 60);
      return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
        .filter(Boolean)
        .join(":");
    },
    secondsToCurrentTime() {
      const h = Math.floor(this.currentTime / 3600);
      const m = Math.floor((this.currentTime % 3600) / 60);
      const s = Math.round(this.currentTime % 60);
      return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
        .filter(Boolean)
        .join(":");
    },
  },
  watch: {
    selectedFile(value) {
      console.log("console this.selectedFile", value);

      // if (value !== null){
      if (
        // this.formTitle != "" &&
        this.formDescription != "" &&
        value !== null
      ) {
        this.submitEnabled = true;
      } else {
        this.submitEnabled = false;
      }
      // var media = URL.createObjectURL(value);

      var video = document.getElementById("video");
      // video.src = media;

      video.onloadedmetadata = () => {
        // window.URL.revokeObjectURL(video.src);
        this.isPlaying = false;
        this.sliderDisabled = false;
        this.duration = video.duration;
        this.minTime = 0;
        this.maxTime = Number(video.duration.toFixed(2));
        this.trimMin = this.minTime;
        this.trimMax = this.maxTime;
        this.originalRange = [0, this.maxTime];

        console.log("console Duration : " + video.duration + " seconds");
        console.log("console originalRange", this.originalRange);
      };

      if (value !== null) {
        video.src = URL.createObjectURL(value);
      }
      // }
    },
    isPlaying(value) {
      value ? (this.sliderDisabled = true) : (this.sliderDisabled = false);
    },
    // formTitle(value) {
    //   console.log("console watch formTitle");
    //   if (
    //     value != "" &&
    //     this.formDescription != "" &&
    //     this.selectedFile !== null
    //   ) {
    //     this.submitEnabled = true;
    //   } else {
    //     this.submitEnabled = false;
    //   }
    // },
    formDescription(value) {
      console.log("console watch formDescription");
      if (value != "" && this.selectedFile !== null) {
        this.submitEnabled = true;
      } else {
        this.submitEnabled = false;
      }
    },
    currentTime(value) {
      console.log("console watch currentTime", value);
      if (parseFloat(value) > parseFloat(this.maxTime)) {
        let video = this.$refs["videoPlayer"];
        console.log(video);
        video.pause();
        // video.load();
        // video.currentTime(this.minTime);
      }
    },
    isPrivate(value) {
      console.log("isPrivate", value);
    },
  },
};
</script>
<template>
  <b-overlay
    :show="loading"
    rounded="lg"
    opacity="0.6"
    style="margin: 0px 10px 0px 0px; align-items: center"
  >
    <div class="card-box">
      <b-form-input
        v-model="formTitle"
        maxLength="100"
        placeholder="Title"
        class="mb-4"
      ></b-form-input>
      <!-- <argon-input type="text" :value="formTitle" placeholder="Title" /> -->
      <b-form-input
        v-model="formDescription"
        maxLength="255"
        placeholder="Description"
        class="mb-4"
      ></b-form-input>
      <b-form-checkbox v-model="isPrivate"> Hidden </b-form-checkbox>
      <input type="file" @change="chooseFile" /><br /><br />
      <div class="text-center">
        <div class="parent-card mb-4">
          <video
            id="video"
            ref="videoPlayer"
            class="video-player-box child-image"
            width="320"
            height="240"
            @timeupdate="onTimeUpdateListener"
            @click="playOrPause"
          ></video>
          <span v-if="selectedFile">
            <img
              v-if="isPlaying"
              src="@/assets/pause-button.png"
              class="pause-button"
              @click="playOrPause"
            />

            <img
              v-else
              src="@/assets/play-button.png"
              class="play-button"
              @click="playOrPause"
            />
          </span>
        </div>

        <div class="d-flex justify-content-between">
          <div style="max-width: 16%">
            <h5 style="text-align: start; font-size: clamp(12px, 1.5vw, 40px)">
              START TIME:(seconds)&nbsp;&nbsp;
            </h5>
            <b-form-input
              id="minInput"
              type="number"
              step="0.10"
              :min="trimMin"
              :max="trimMax"
              @blur="blur"
              v-model="trimMin"
              @input="checkMinInput"
              @keydown.enter.prevent="sliderMin"
              style="display: block"
            />
            <label style="font-size: clamp(12px, 1.5vw, 40px); display: none">
              {{ secondsToMinTime }}</label
            >
          </div>
          <h1>{{ secondsToCurrentTime }}</h1>
          <div style="max-width: 16%">
            <h5 style="text-align: end; font-size: clamp(12px, 1.5vw, 40px)">
              END TIME:(seconds)&nbsp;&nbsp;
            </h5>
            <b-form-input
              id="maxInput"
              type="number"
              step="0.10"
              :min="trimMin"
              :max="trimMax"
              @blur="blur"
              v-model="trimMax"
              @input="checkMaxInput"
              @keydown.enter.prevent="sliderMax"
              style="display: block"
            />
            <label style="font-size: clamp(12px, 1.5vw, 40px); display: none">
              {{ secondsToMaxTime }}
            </label>
          </div>
        </div>
        <div id="app-1">
          <div class="range-slider">
            <Slider
              v-model="originalRange"
              :min="0"
              :max="maxTime"
              :step="0.01"
              :disabled="sliderDisabled"
              @update="sliderUpdate"
              @change="sliderChanged"
              :format="format"
            />
          </div>
        </div>
      </div>
      <div class="mt-5">
        <b-button variant="success" :disabled="!submitEnabled"
          ><span v-if="loading">Loading</span
          ><span v-else @click="upload">Submit</span></b-button
        >
      </div>
    </div>
  </b-overlay>
</template>
<style scoped>
.parent-card {
  max-height: 30rem;
  min-height: 10rem;
  max-width: 100%;
  display: grid;
  place-items: center;
  position: relative;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
}
.child-image {
  padding: 10px 10px;
  height: 100%;
  width: 100%;
}
.video-player-box {
  display: block;
  max-width: 100% !important;
  max-height: 30rem;
  background-color: #f0f8ff;
}
#video:hover {
  cursor: pointer;
}
.parent-card:hover .pause-button {
  opacity: 1;
}
/* .play-button:hover {
  width: 3rem;
  height: 3rem;
  transition-timing-function: ease-in;
  transition: 0.5s;
  cursor: pointer;
} */
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-timing-function: ease-in;
  transition: 0.5s;
  width: 2rem;
  height: 2rem;
}
.pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-timing-function: ease-in;
  transition: 0.5s;
  width: 2rem;
  height: 2rem;
  opacity: 0;
}
/* .pause-button:hover {
  opacity:1
} */

.card-box {
  /* width: 100%; */
  background-color: white;
  padding: 2rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  border-radius: 1rem;
}
.range-slider {
  min-width: 15rem;
  margin: 3rem 0rem;
  text-align: center;
  position: relative;
}

.range-slider input[type="range"] {
  position: absolute;
  left: 0;
  bottom: 0;
}

input[type="number"] {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 1.6em;
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"]:invalid,
input[type="number"]:out-of-range {
  /* border: 2px solid #ff6347; */
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #2497e3;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #2497e3;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #2497e3;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #2497e3;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type="range"]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #2497e3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #a1d0ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
#currentTime {
  -webkit-appearance: none;
}
#currentTime::-webkit-slider-thumb {
  /* pointer-events: none; */
  border: 1px solid rgba(32, 176, 121);
  background: rgba(32, 176, 121);
}
#currentTime::-webkit-progress-value {
  background-color: red;
}
</style>
