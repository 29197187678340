import { createRouter, createWebHistory } from "vue-router";

import { user } from "@/config/api/User";
import * as ApiGenerate from "@/config/ApiGenerate";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;

router.beforeEach((routeTo, routeFrom, next) => {
  const token = getCookie("key");
  // console.log("token", token);
  // console.log("routeTo.name", routeTo.name); 

  if (routeTo.name !== "Error404") {
    checkUser().then((result) => {
      // console.log(result);
      if (token === "" || !result) {
        // console.log("logged out");
        return next("../views/components/Error404");
      } else {
        // console.log("logged in");
        next();
      }
    });
  }
  else {
    next();
  }
  
  // }
});
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

async function checkUser() {
  const api = user.authenticateUser;
  // console.log("api", api);
  try {
    let response = await ApiGenerate.generateAPI(api);
    console.log("response checkUser", response);
    if (response.data.success) {
      return true;
    }
    return false;
  } catch (error) {
    console.log("error", error);
    return false;
  }
}
