export const addVideo = {
  createVideoPost: {
    url: "createvideopost",
    method: "POST",
    data: null,
  },
  directCreaterUpload: {
    url: "https://api.cloudflare.com/client/v4/accounts/8c0f322fb5c5ed8326dc88fe6666c36b/stream/direct_upload",
    method: "POST",
    data: {
      'maxDurationSeconds': 3600,
      'requireSignedURLs': false,
      'thumbnailTimestampPct': 0.568427,
    },
  },
  uploadVideoFromUrl: {
    url: "https://upload.videodelivery.net/",
    method: "POST",
    data: null,
  },
  getVideoInformation: {
    url: "https://api.cloudflare.com/client/v4/accounts/8c0f322fb5c5ed8326dc88fe6666c36b/stream/",
    method: "GET",
    data: null,
  },
};
