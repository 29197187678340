import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import "@/config/ApiGenerate";

import BootstrapVue3 from "bootstrap-vue-3";
import axios from "axios";
import Toast from "vue-toastification"; 
import "vue-toastification/dist/index.css";
// import VueFormulate from '@braid/vue-formulate' 

const appInstance = createApp(App);


// appInstance.use(VueFormulate)
appInstance.use(BootstrapVue3);
appInstance.use(Toast);
appInstance.config.globalProperties.axios = axios; 

appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");
