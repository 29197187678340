<script>
import { ViewVideos } from "@/config/api/ViewVideos";
import * as toast from "@/config/toast";
import * as ApiGenerate from "@/config/ApiGenerate";
export default {
  props: ["selectedVideoDelete", "showDeleteModal"],

  mounted() {
    this.values = { ...this.selectedVideoDelete };
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    closeModal() {
      console.log("closeModal");
      this.$emit("close-modal");
    },
    async deleteVideo() {
      this.loading = true;
      const api = ViewVideos.deleteVideo;
      api.data = {
        id: this.selectedVideoDelete.id,
      };
      try {
        let response = await ApiGenerate.generateAPI(api);
        console.log("response", response);
        toast.showToast("success", "Deleted successfully!");
        this.$emit("confirmed-delete", this.selectedVideoDelete);
      } catch (error) {
        console.log("error", error);
        toast.showToast("error", "Could not delete. Try again.");
      } finally {
        console.log("finally");
        this.loading = false;
        this.closeModal();
      }
    },
    deleteModalClick(event) {
      console.log("deleteModalClick", event.target.classList.value);
      if (
        event.target.classList.value === "vue-modal" ||
        event.target.classList.value ===
          "vue-modal fade-enter-active fade-enter-to"
      ) {
        this.$emit("close-modal");
      }
    },
  },
  watch: {
    selectedVideoDelete(val) {
      if (val) {
        this.values = { ...val };
      }
    },
  },
};
</script>
<template>
  <transition name="fade">
    <div v-show="showDeleteModal" class="vue-modal" @click="deleteModalClick">
      <transition name="drop-in" v-show="showDeleteModal">
        <div class="vue-modal-inner">
          <div class="vue-modal-content">
            <div
              class="d-flex justify-content-between border-bottom border-light p-3"
            >
              <div><h5>Delete Video</h5></div>
              <div>
                <i class="fa fa-times fa-lg pointer" @click="closeModal"></i>
              </div>
            </div>
            <!-- <div><label>Are you sure?</label></div> -->
            <div class="mt-3" style="text-align-last: end">
              <span v-if="!loading"><b-button
                size="sm"
                variant="success"
                @click="closeModal"
                :disabled="loading"
              >
                Cancel </b-button
              ></span>&nbsp;&nbsp;
              <b-button
                size="sm"
                variant="danger"
                @click="deleteVideo"
                :disabled="loading"
              >
                <span v-if="loading">Loading</span>
                <span v-else>Delete</span>
              </b-button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<style scoped>
.vue-modal-content {
  padding-bottom: 0rem;
}
.vue-modal-inner {
  position: sticky;
  top: 35%;
  left: 30%;
}
</style>
