export const ViewVideos = {
  getVideoPosts: {
    url: "getvideoposts",
    method: "GET",
    data: null,
  },
  getSortedVideos: {
    url: "gettimelinevideos",
    method: "GET",
    data: null,
  },
  updateVideo: {
    url: "updatevideopost",
    method: "POST",
    data: null,
  },
  saveTimeline: {
    url: "updatetimelinevideos",
    method: "POST",
    data: null,
  },
  deleteVideo: {
    url: "deletevideopost",
    method: "POST",
    data: null,
  },
  getAllVideosCloudFlare: {
    url:
      "https://api.cloudflare.com/client/v4/accounts/8c0f322fb5c5ed8326dc88fe6666c36b/stream",
    method: "GET",
    data: null,
  },
};
