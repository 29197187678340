<script>
import { ViewVideos } from "@/config/api/ViewVideos";
import * as toast from "@/config/toast";
import * as ApiGenerate from "@/config/ApiGenerate";
export default {
  props: ["selectedVideoEdit", "showEditModal"],

  mounted() {
    this.values = { ...this.selectedVideoEdit };
  },
  data() {
    return {
      loading: false,
      formTitle: "",
      formDescription: "",
      isPrivate: false,
    };
  },
  methods: {
    // change(data) {
    //   function rgbToHexFunc(r, g, b) {
    //     const rgb = (r << 16) | (g << 8) | (b << 0);
    //     return "#" + (0x1000000 + rgb).toString(16).slice(1);
    //   }

    //   console.log("change");

    //   this.values.color = data;
    //   if (data.includes("rgb")) {
    //     let rgbArray = data.split(",");
    //     rgbArray[0] = rgbArray[0].substring(5, rgbArray[0].length);

    //     this.selectedColor = rgbToHexFunc(
    //       rgbArray[0],
    //       rgbArray[1],
    //       rgbArray[2]
    //     ).replace(/\s/g, "");
    //   }
    // },
    closeModal() {
      console.log("closeModal");
      this.$emit("close-modal");
    },

    async updateDetails() {
      console.log("updateDetails");
      this.loading = true;
      const api = ViewVideos.updateVideo;
      api.data = {
        id: this.selectedVideoEdit.id,
        title: this.formTitle,
        description: this.formDescription,
        is_private: this.isPrivate,
      };
      console.log("api", api);
      try {
        let response = await ApiGenerate.generateAPI(api);
        console.log("response", response);
        toast.showToast("success", "Updated successfully!");
        this.closeModal();
        this.$emit("confirmed-edit", response.data.videopost);
      } catch (error) {
        console.log("error", error);
        toast.showToast("error", "Updated failed!");
      } finally {
        console.log("finally");
        this.loading = false;
      }
    },
    deleteModalClick(event) {
      console.log("deleteModalClick", event.target.classList.value);
      if (
        event.target.classList.value === "vue-modal" ||
        event.target.classList.value ===
          "vue-modal fade-enter-active fade-enter-to"
      ) {
        this.$emit("close-modal");
      }
    },
  },
  computed: {
    checkTitle() {
      return "Edit Details";
    },
  },
  watch: {
    selectedVideoEdit(value) {
      console.log("selectedVideoEdit", value);
      if (value !== null) {
        this.formTitle = value.title;
        this.formDescription = value.description;
        this.isPrivate = value.is_private === "1" ? true : false;
      }

      //   if (value) {
      //     this.values = { ...value };
      //   }
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div v-show="showEditModal" class="vue-modal" @click="deleteModalClick">
      <transition name="drop-in" v-show="showEditModal">
        <div class="vue-modal-inner">
          <div class="vue-modal-content">
            <div
              class="d-flex justify-content-between border-bottom border-light p-3"
            >
              <div><h5>Edit Details</h5></div>
              <div>
                <i class="fa fa-times fa-lg pointer" @click="closeModal"></i>
              </div>
            </div>
            <div class="p-3">
              <b-form-input
                v-model="formTitle"
                placeholder="Title"
                class="mb-4"
              ></b-form-input>
              <b-form-input
                v-model="formDescription"
                placeholder="Description"
                class="mb-4"
              ></b-form-input>
              <b-form-checkbox v-model="isPrivate"> Hidden </b-form-checkbox>
              <div class="float-end">
                <button
                  :disabled="loading"
                  class="btn btn-success"
                  type="submit"
                  @click="updateDetails"
                >
                  <span v-if="loading">Loading</span>
                  <span v-else>Update</span>
                </button>
              </div>
              <!-- <p>hi</p> -->
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<style>
@media only screen and (min-width: 700px) {
  /* CSS that should be displayed if width is equal to or greater than 700px goes here */
  .vue-modal-inner {
    max-width: 50%;
  }
}
@media only screen and (min-width: 1200px) {
  #upload_video_icon,
  #sort_videos_icon {
    display: none !important;
  }
}
@media only screen and (max-width: 400px) {
  /* CSS that should be displayed if width is equal to or less than 400px goes here */
  .vue-modal-inner {
    max-width: 100%;
  }
}
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.vue-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}
.vue-modal-inner {
  margin: 2rem auto;
}
.vue-modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border-radius: 0.3rem;
  padding: 1rem 1rem 2rem 1rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-out;
}
.drop-in-enter-from,
.drop-in-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.pointer {
  cursor: pointer;
}
</style>
